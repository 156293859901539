import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';
import SEO from './SEO';

const Header = () => (
  <>
    <SEO/>
    <header className="sticky top-0 bg-white shadow z-50">
      <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-8 px-8">
        <div className="flex items-center text-2xl">
          <div className="w-15 mt-3">
            <LogoIcon />
          </div>
          Layla&Aidan
        </div>
        <div className="flex mt-4 sm:mt-0 text-lg">
          <AnchorLink className="px-4" href="#details">
            Details 
          </AnchorLink>
          <AnchorLink className="px-4" href="#lodging">
            Lodging
          </AnchorLink>
          <AnchorLink className="px-4" href="#photos">
            Photos  
          </AnchorLink>
          <AnchorLink className="px-4" href="#Gifts">
            Gifts
          </AnchorLink>
        </div>
      </div>
    </header>
  </>
);

export default Header;
