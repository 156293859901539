import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ }) => {

    const data = {
        title: "Layla&Aidan",
        description: "Layla and Aidan's Wedding",
        baseUrl: "https://laylaandaidan.com"
    }

    if (data.baseUrl === '' && typeof window !== 'undefined') {
        data.baseUrl = window.location.origin;
    }
            
    const title = data.title;
    const description = data.description;
    const url = new URL(data.baseUrl);


    return (
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={url} />
            <meta name="description" content={description} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
        </Helmet>
  );
};

export default SEO;